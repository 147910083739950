/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { supportsPassiveEvents } from 'detect-passive-events';
import classNames from 'classnames';

import styles from './FloatingButton.module.scss';

const FloatingButton = ({ gap, border, expand, children, onClick }) => {
  // const [shouldRender, setShouldRender] = useState(false);
  const goToEl = useRef(null);

  // useEffect(() => {
  //   setShouldRender(true);
  // }, []);

  const handleScroll = useCallback(() => {
    if (!goToEl.current) return;

    const parent = goToEl.current.parentElement.parentElement;
    if (border) {
      const path = document.querySelector('#circle');
      const pathLength = path.getTotalLength();
      const scrollPercentage =
        (document.documentElement.scrollTop + document.body.scrollTop) /
        (parent.clientHeight - document.documentElement.clientHeight);

      const drawLength = scrollPercentage > 1 ? pathLength : pathLength * scrollPercentage;

      path.style.strokeDasharray = pathLength + ' ' + pathLength;
      path.style.strokeDashoffset = pathLength;
      path.style.strokeDashoffset = pathLength - drawLength;
    }

    goToEl.current.style.bottom = gap + 'px';

    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      goToEl.current.style.opacity = '1';
    } else {
      goToEl.current.style.opacity = '0';
    }
  }, [border, gap, goToEl]);

  useEffect(() => {
    const scrollEventOptions = supportsPassiveEvents ? { passive: true } : false;
    window.addEventListener('scroll', handleScroll, scrollEventOptions);

    return () => {
      window.removeEventListener('scroll', handleScroll, scrollEventOptions);
    };
  }, [handleScroll]);

  // if (!shouldRender) return null;

  const isSafari =
    // eslint-disable-next-line no-restricted-globals
    typeof self === 'object' &&
    navigator.userAgent.indexOf('Safari') !== -1 &&
    navigator.userAgent.indexOf('Chrome') === -1;

  return (
    <span
      className={classNames(styles.floatingButton, {
        [styles.floatingButtonLarge]: expand,
        [styles.floatingButtonSafari]: isSafari && expand,
      })}
      onClick={onClick}
      ref={goToEl}
    >
      {children}
      {border && [
        <svg viewBox={'32.5 5 100 100'} key={'inner-circle'}>
          <path
            fill={'none'}
            stroke={'#f7f7f7'}
            strokeWidth={'3'}
            d={
              'M83,99.916c-24.806,0-44.916-20.109-44.916-44.916 c0-24.806,20.109-44.917,44.916-44.917c24.807,0,44.916,20.11,44.916,44.917C127.916,79.807,107.807,99.916,83,99.916z'
            }
          />
        </svg>,
        <svg viewBox={'32.5 5 100 100'} id={'circle-svg'} key={'outer-circle'}>
          <path
            id={'circle'}
            className={styles.circle}
            fill={'none'}
            strokeWidth={'3'}
            d={
              'M83,99.916c-24.806,0-44.916-20.109-44.916-44.916 c0-24.806,20.109-44.917,44.916-44.917c24.807,0,44.916,20.11,44.916,44.917C127.916,79.807,107.807,99.916,83,99.916z'
            }
          />
        </svg>,
      ]}
    </span>
  );
};

FloatingButton.propTypes = {
  onClick: PropTypes.func,
  showUnder: PropTypes.number,
  comingSoon: PropTypes.bool,
  border: PropTypes.bool,
  gap: PropTypes.number,
};

FloatingButton.displayName = 'FloatingButton';
export default FloatingButton;

/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import clickoutScript from '../../utils/clickoutScript';
import { contentApi, getValidResponseBody } from '../../api';
import { setDailies } from '../../state/dailies/dailies';

import { ReactComponent as ShoppingCartIcon } from '../../images/icons/ShoppingCart.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';

import styles from './DailyBar.module.scss';
import loadVisibleComponent from '../../utils/loadVisibleComponent';
//Todo: load those components code on demand
const Drawer = loadVisibleComponent(() => import('../Drawer/Drawer'));
const Button = loadVisibleComponent(() => import('../Button/Button'));

const DailyBar = ({ dailies, intl, clickout, setReduxDailies }) => {
  const location = useLocation();
  const { push } = useHistory();

  const openDailies = () => {
    push('#dailies');
  };

  const loadDailiesOnDemand = () => {
    (async () => {
      if (!dailies) {
        const dailiesUpdated = await contentApi.getDailies();
        const dailiesUpdatedBody = getValidResponseBody(dailiesUpdated);
        if (dailiesUpdatedBody) setReduxDailies(dailiesUpdatedBody);
      }
    })();
  };

  return (
    <div data-testid="dailyBar">
      <span onClick={openDailies} className={styles.dailiesOpener}>
        <ShoppingCartIcon />
      </span>
      <Drawer
        direction="LeftToRight"
        trigger="dailies"
        onOverlayOpened={loadDailiesOnDemand}
        onOverlayClick={() => push({ ...location, hash: '' })}
      >
        <div className={classNames(styles.scroll, 'container')}>
          <div className="row">
            <div className={classNames(styles.dailiesBar, 'col-12-xs')}>
              <header>
                <FormattedMessage id="common.dailies" defaultMessage="Groceries" />{' '}
                <button
                  aria-label={intl.messages['common.menu.closeMenu']}
                  onClick={() => push({ ...location, hash: '' })}
                >
                  <CloseIcon />
                </button>
              </header>
              <section>
                {dailies &&
                  dailies.map((dailies, index) => {
                    return (
                      <div className={styles.dailySection} key={dailies.name + index}>
                        <h3>
                          <img
                            src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,fl_lossy,c_scale,w_30/${dailies.img}`}
                            alt={dailies.name}
                            width="30"
                          />
                          {dailies.name}
                        </h3>
                        {dailies.posts.map((post, index) => {
                          return (
                            <div className={styles.daily} key={post.title + index}>
                              <div className={styles.imageContainer}>
                                <img
                                  src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,fl_lossy,c_scale,w_59/${post.shop_logo}`}
                                  alt={post.title}
                                  width="59"
                                />
                              </div>
                              <div className={styles.postContent}>
                                <h4>{post.title}</h4>
                                <p>{post.content}</p>
                                <a
                                  onClick={() => clickoutScript(post.url, clickout, false)}
                                  className={styles.dailyButton}
                                  href={clickoutScript(post.url, clickout, true)}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <Button variation="secondary">
                                    <FormattedMessage id="shop.goTo" defaultMessage="Go to" /> {post.title}
                                  </Button>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </section>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setReduxDailies: (dailies) => {
    dispatch(setDailies(dailies));
  },
});

const mapStateToProps = ({ dailies, intl, clickout }) => ({ dailies, intl, clickout });
DailyBar.displayName = 'DailyBar';

export default connect(mapStateToProps, mapDispatchToProps)(DailyBar);

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

export const ICON_LEFT = 'left';
export const ICON_RIGHT = 'right';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const WHITE = 'white';

const Button = ({
  boxed,
  children,
  classList = {},
  disabled = false,
  icon,
  iconPosition,
  inverted = false,
  small,
  trivial,
  variation = PRIMARY,
  ...props
}) => {
  const rootStyle = classNames(styles.buttonContainer, classList.root, styles[variation], {
    [styles.disabled]: disabled ? true : false,
    [styles.iconLeft]: iconPosition === ICON_LEFT ? true : false,
    [styles.iconRight]: iconPosition === ICON_RIGHT ? true : false,
    [styles.inverted]: inverted ? true : false,
    [styles.trivial]: trivial ? true : false,
    [styles.boxed]: boxed ? true : false,
    [styles.small]: small ? true : false,
    buttonLarge: !small ? true : false,
  });
  const { className } = props;
  return (
    <button disabled={disabled} {...props} className={classNames(rootStyle, className)}>
      {iconPosition === ICON_LEFT && <span className={classList.icon}>{icon}</span>}
      {children}
      {iconPosition === ICON_RIGHT && <span className={classList.icon}>{icon}</span>}
    </button>
  );
};

Button.displayName = 'Button';

Button.propTypes = {
  boxed: PropTypes.bool,
  children: PropTypes.node,
  classList: PropTypes.shape({
    root: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf([ICON_LEFT, ICON_RIGHT]),
  inverted: PropTypes.bool,
  small: PropTypes.bool,
  trivial: PropTypes.bool,
  variation: PropTypes.oneOf([PRIMARY, SECONDARY, WHITE]),
};

export default Button;

import React, { useState } from 'react';

export const ImageCropSearchContext = React.createContext();

export const ImageCropSearchProvider = ({ children }) => {
  const [cropData, setCropData] = useState(null);
  return (
    <ImageCropSearchContext.Provider
      value={{
        imageCropData: cropData,
        setImageCropData: setCropData,
      }}
    >
      {children}
    </ImageCropSearchContext.Provider>
  );
};

/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Button from '../Button/Button';

import { ReactComponent as ErrorGraphic } from '../../images/icons/Error-page_webshop-nl.svg';

import styles from './ErrorPage.module.scss';

const ErrorPage = () => {
  return (
    <>
      {/* <Helmet>
        <title>
          <FormattedMessage id="error.title" defaultMessage="Back to homepage" />
        </title>
      </Helmet> */}
      <div className={classNames('container')} id="readyForCrawler">
        <div className="row">
          <div className={classNames(styles.container, 'col-12-xs')}>
            <ErrorGraphic />
            <h3 data-testid="ErrorPageTitle" className={styles.title}>
              <FormattedMessage id="error.title" defaultMessage="Server Error" />
            </h3>
            <p>
              <FormattedMessage id="error.description" defaultMessage="Unable to view page, please try again later." />
            </p>
            <div className="row">
              <a
                href="/"
                className={classNames(styles.goHomeLink, 'col-12-xs offset-2-m col-8-m offset-4-xl col-4-xl')}
              >
                <Button classList={{ root: classNames(styles.button) }} variation="secondary">
                  <FormattedMessage id="error.link" defaultMessage="Back to homepage" />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;

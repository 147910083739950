import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNotification, ERROR_NOTIFICATION, removeLastNotification } from '../../state/notifications/notifications';

const useNotifications = () => {
  const notifications = useSelector(({ notifications }) => notifications.list);
  const dispatch = useDispatch();

  const sendNotification = useCallback(
    (text, status) => {
      dispatch(addNotification({ text, status: status || ERROR_NOTIFICATION }));
    },
    [dispatch]
  );

  const removeNotification = useCallback(() => {
    dispatch(removeLastNotification());
  }, [dispatch]);

  return { notifications, sendNotification, removeNotification };
};

export default useNotifications;
